<template>
  <div class="">
    <EventForm ref="form" action="Update" v-model="formData" @formSubmitted="updateExternalEvent" />
  </div>
</template>
  
  <script>
import EventForm from "@/views/components/event/EventForm";
import EventService from "@/services/EventService";

export default {
  name: "UpdateEvent",
  components: {
    EventForm
  },
  data() {
    return {
      eventService: new EventService(),
      id: this.$route.fullPath.split('/').pop(),
      formData: {
      },
    }
  },
  methods: {
    updateExternalEvent(e) {
      this.$refs.form.loading = true;
      this.eventService.update(this.formData._id, e).then(res => {
        this.$router.push('/event')
      }).catch(err => {
        console.log(err);
       }).finally(() => {
        this.$refs.form.loading = false;
      })
    },
    mappingData(data) {
      return {
        ...data,
       }
    },
    getEvent() {
      this.eventService.show(this.id).then(res => {
        this.formData = this.mappingData(res);
        this.formData.topics = this.formData.topics.map(e => e._id);
        this.formData.languages = this.formData.languages.map(e => e._id);
      }).catch(err => { }).finally(() => { })
    }
  },
  mounted() {
    this.getEvent()
  }
}
</script>
  
  <style scoped>
  </style>
  